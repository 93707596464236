input[type="range"] {
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #4aa9de;
  border-radius: 5px;
  border: 0px solid #000000;
}

input[type="range"]::-webkit-slider-thumb {
  box-shadow: 0px 0px 1px #000000;
  border: 0px solid #000000;
  height: 13px;
  width: 4px;
  border-radius: 50px;
  background: #2a6586;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -4px;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #4aa9de;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #4aa9de;
  border-radius: 5px;
  border: 0px solid #000000;
}

input[type="range"]::-moz-range-thumb {
  box-shadow: 0px 0px 1px #000000;
  border: 0px solid #000000;
  height: 13px;
  width: 4px;
  border-radius: 50px;
  background: #2a6586;
  cursor: pointer;
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type="range"]::-ms-fill-lower {
  background: #4aa9de;
  border: 0px solid #000000;
  border-radius: 10px;
  box-shadow: 0px 0px 0px #000000;
}

input[type="range"]::-ms-fill-upper {
  background: #4aa9de;
  border: 0px solid #000000;
  border-radius: 10px;
  box-shadow: 0px 0px 0px #000000;
}

input[type="range"]::-ms-thumb {
  box-shadow: 0px 0px 1px #000000;
  border: 0px solid #000000;
  height: 13px;
  width: 4px;
  border-radius: 50px;
  background: #2a6586;
  cursor: pointer;
}

input[type="range"]:focus::-ms-fill-lower {
  background: #4aa9de;
}

input[type="range"]:focus::-ms-fill-upper {
  background: #4aa9de;
}

.plsoptions {
  justify-self: flex-end;
}
.modal {
  background-color: rgb(255, 170, 170);
}
